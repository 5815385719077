import type { Attribute, Common, Utils } from '@strapi/strapi';

/* Below you will find TS utility types required to use the Strapi-generated types.
 *
 * Most of the types were taken as-is from the Strapi v4 blog:
 *   https://strapi.io/blog/improve-your-frontend-experience-with-strapi-types-and-type-script
 *
 * If the type inference doesn't work as you would expect it, check the following:
 * - Whether you are using the right utility. In most cases you would
 *   only need `GetValues` or `ApiResponseData`.
 * - Whether you exported the latest types from `strapi-cms`.
 * - Whether the CMS UI, in fact, shows the type as you would expect it.
 *
 * If all checks out, then it might be a bug in the type utilities that you would have to
 * fix. Jumping on Strapi Discord might be the fastest way to do so.
 */
type IDProperty = { id: number };

type InvalidKeys<TSchemaUID extends Common.UID.Schema> = Utils.Object.KeysBy<
  Attribute.GetAll<TSchemaUID>,
  Attribute.Private | Attribute.Password
>;

export type GetValues<TSchemaUID extends Common.UID.Schema> = {
  [TKey in Attribute.GetOptionalKeys<TSchemaUID>]?: Attribute.Get<
    TSchemaUID,
    TKey
  > extends infer TAttribute extends Attribute.Attribute
    ? GetValue<TAttribute>
    : never;
} & {
  [TKey in Attribute.GetRequiredKeys<TSchemaUID>]-?: Attribute.Get<
    TSchemaUID,
    TKey
  > extends infer TAttribute extends Attribute.Attribute
    ? GetValue<TAttribute>
    : never;
} extends infer TValues
  ? // Remove invalid keys (private, password)
    Omit<TValues, InvalidKeys<TSchemaUID>>
  : never;

export type GetDynamicZoneValues<TComponentUID extends Common.UID.Schema> =
  GetValues<TComponentUID> & IDProperty;

// TODO: This only supports oneToOne relations at the moment. Typescript couldn't infer the correct relation type
//       using the approach suggested by Strapi, so I simplified the type. Not sure what the issue is, but this could be
//       a limitation of TS v4. Latest Strapi offers a GetRelationValue type out of the box, we should re-evaluate this utility type
//       after upgrading the Strapi version.
type RelationValue<TAttribute extends Attribute.Attribute> =
  TAttribute extends Attribute.Relation<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    infer _TOrigin,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    infer _TRelationKind,
    infer TTarget
  >
    ? APIResponse<TTarget> | null
    : never;

type ComponentValue<TAttribute extends Attribute.Attribute> =
  TAttribute extends Attribute.Component<infer TComponentUID, infer TRepeatable>
    ? Utils.Expression.If<
        TRepeatable,
        (IDProperty & GetValues<TComponentUID>)[],
        (IDProperty & GetValues<TComponentUID>) | null
      >
    : never;

export type DynamicZoneValue<TAttribute extends Attribute.Attribute> =
  TAttribute extends Attribute.DynamicZone<infer TComponentUIDs>
    ? Array<
        Utils.Array.Values<TComponentUIDs> extends infer TComponentUID
          ? TComponentUID extends Common.UID.Component
            ? { __component: TComponentUID } & IDProperty &
                GetValues<TComponentUID>
            : never
          : never
      >
    : never;

type MediaValue<TAttribute extends Attribute.Attribute> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TAttribute extends Attribute.Media<infer _TKind, infer TMultiple>
    ? Utils.Expression.If<
        TMultiple,
        APIResponseCollection<'plugin::upload.file'>,
        APIResponse<'plugin::upload.file'> | null
      >
    : never;

export type GetValue<TAttribute extends Attribute.Attribute> =
  Utils.Expression.If<
    Utils.Expression.IsNotNever<TAttribute>,
    Utils.Expression.MatchFirst<
      [
        // Relation
        [
          Utils.Expression.Extends<TAttribute, Attribute.OfType<'relation'>>,
          RelationValue<TAttribute>
        ],
        // DynamicZone
        [
          Utils.Expression.Extends<TAttribute, Attribute.OfType<'dynamiczone'>>,
          DynamicZoneValue<TAttribute>
        ],
        // Component
        [
          Utils.Expression.Extends<TAttribute, Attribute.OfType<'component'>>,
          ComponentValue<TAttribute>
        ],
        // Media
        [
          Utils.Expression.Extends<TAttribute, Attribute.OfType<'media'>>,
          MediaValue<TAttribute>
        ],
        // Fallback
        // If none of the above attribute type, fallback to the original Attribute.GetValue (while making sure it's an attribute)
        [Utils.Expression.True, Attribute.GetValue<TAttribute, unknown>]
      ],
      unknown
    >,
    unknown
  >;

export interface APIResponseData<TContentTypeUID extends Common.UID.ContentType>
  extends IDProperty {
  attributes: GetValues<TContentTypeUID>;
}

export interface APIResponseCollectionMetadata {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
}

export interface APIResponse<TContentTypeUID extends Common.UID.ContentType> {
  // FIXME: this won't be null most of the time, but it will be null in case of an optional upload attribute.
  //        The Strapi utils cannot correctly infer this, so it is safer to assume all of data attributes may be null
  data: APIResponseData<TContentTypeUID> | null;
  meta?: APIResponseCollectionMetadata;
}

export interface APIResponseCollection<
  TContentTypeUID extends Common.UID.ContentType
> {
  data: APIResponseData<TContentTypeUID>[];
  meta: APIResponseCollectionMetadata;
}

export type StrapiPageData = APIResponseData<'api::page.page'>['attributes'];
export type StrapiJobData = APIResponseData<'api::job.job'>['attributes'];
export type StrapiAnnouncementBarData =
  GetValues<'api::announcement-bar.announcement-bar'>;
export type StrapiHomepageData = GetValues<'api::homepage.homepage'>;

export const isStrapiPage = (page: unknown): page is StrapiPageData => {
  return !!page && typeof page === 'object' && 'locale' in page;
};

export type DocumentAttributes =
  | APIResponseData<'api::page.page'>['attributes']
  | APIResponseData<'api::homepage.homepage'>['attributes'];

export type Block = NonNullable<DocumentAttributes['items']>[number];

export type DynamicBlock<T extends Block['__component']> = Extract<
  Block,
  { __component: T }
>;

export type BlockComponentProps<T extends Block['__component']> = {
  block: GetDynamicZoneValues<T>;
};
